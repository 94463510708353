import APIModel from "../APIModel";

export default class SigningTypes extends APIModel {
    private readonly resourceUrl: string;

    constructor() {
        super();
        this.resourceUrl = "signing_types";
    }

    async getList(params: any = {}, headers: any = {}): Promise<any> {
        return await this.http
            .setHeaders(headers)
            .get(`${this.resourceUrl}/list`, params);
    }
}
